<template>
    <v-container>
        <v-container max-width="1100" class="mx-auto">
            <h3>Pacientes</h3>
            <v-divider class="my-2"></v-divider>
        </v-container>
        <v-form ref="ref">
            <v-row> 
                <v-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" :cols="12">
                    <v-text-field
                    v-model="nm_paciente"
                    label="NOME"
                    outlined
                    required 
                    :rules="regra()"
                    ></v-text-field>
                </v-col>
                <v-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" :cols="12">
                    <v-text-field
                    v-model="nr_cpf"
                    label="CPF"
                    outlined
                    required 
                    :rules="regra()"
                    ></v-text-field>
                </v-col>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12" class="text-end">
                    <v-btn class="my-2" @click="get_pacientes(nm_paciente, nr_cpf)" outlined>pesquisar<v-icon class="ma-2">mdi-magnify</v-icon></v-btn>
                </v-col>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12" class="py-6 text-end">
                    <Paciente @onCreatedPaciente="atualiza_paciente()"/>
                </v-col>
            </v-row>
        </v-form>
        
        <v-data-table
            :loading="loading"
            :items="arr"
            :headers="headers"
            show-expand 
            single-expand 
            :expanded.sync="expanded"
            item-key="cd_paciente" 
            :search="search" 
        >
            <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Pesquisar"
                    class="mx-4"
                ></v-text-field>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" style="background: #ddd">
                    <v-row class="mt-2">
                        <v-col cols="12">
                            <AgendamentosDoPaciente app="" :paciente="item"/>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-text-field
                                label="NOME"
                                v-model="item.nm_paciente"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-text-field
                                label="DATA NASC"
                                v-model="item.dt_nascimento"
                                outlined
                                type="date"
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="2" :lg="2" :xl="2" :cols="12">
                            <v-autocomplete
                                hide-details="true"
                                label="SEXO"
                                class="ma-0"
                                v-model="item.tp_sexo"
                                item-text="value"
                                item-value="tp_sexo"
                                outlined
                                :items="tp_sexo"
                                :style="{ 'background-color': corDeFundo }"
                            ></v-autocomplete>
                        </v-col>
                        <v-col :md="2" :lg="2" :xl="2" :cols="12">
                            <v-autocomplete
                                hide-details="true"
                                label="ESTADO CIVIL"
                                class="ma-0"
                                v-model="item.tp_estado_civil"
                                item-text="value"
                                item-value="tp_estado_civil"
                                outlined
                                :items="tp_estado_civil"
                                :style="{ 'background-color': corDeFundo }"
                            ></v-autocomplete>
                        </v-col>
                        <v-col :md="2" :lg="2" :xl="2" :cols="12">
                            <v-text-field
                                label="CPF"
                                v-model="item.nr_cpf"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="2" :lg="2" :xl="2" :cols="12">
                            <v-text-field
                                label="IDENTIDADE"
                                v-model="item.nr_identidade"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="2" :lg="2" :xl="2" :cols="12">
                            <v-autocomplete
                                hide-details="true"
                                label="ORGÃO EMISSOR"
                                class="ma-0"
                                v-model="item.ds_om_identidade"
                                outlined
                                :items="ds_om_identidade"
                                item-text="cd_sigla"
                                item-value="ds_om_identidade"
                                :style="{ 'background-color': corDeFundo }"
                            ></v-autocomplete>
                        </v-col>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-text-field
                                label="NOME DA MÃE"
                                v-model="item.nm_mae"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-autocomplete
                                hide-details="true"
                                label="CIDADANIA"
                                class="ma-0"
                                :loading="loading_cidadanias"
                                v-model="item.cd_cidadania"
                                item-text="ds_cidadania"
                                item-value="cd_cidadania"
                                outlined
                                :items="cidadanias"
                                :style="{ 'background-color': corDeFundo }"
                            ></v-autocomplete>
                        </v-col>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-autocomplete
                                hide-details="true"
                                label="PROFISSAO"
                                class="ma-0"
                                :loading="loading_profissoes"
                                v-model="item.cd_profissao"
                                item-text="nm_profissao"
                                item-value="cd_profissao"
                                outlined
                                :items="profissoes"
                                :style="{ 'background-color': corDeFundo }"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="12"><v-divider class="ma-1"></v-divider></v-col>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-text-field
                                label="ENDEREÇO"
                                v-model="item.ds_endereco"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="2" :lg="2" :xl="2" :cols="12">
                            <v-text-field
                            label="NÚMERO"
                            v-model="item.nr_endereco"
                            outlined
                            :style="{ 'background-color': corDeFundo }"
                            hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="3" :lg="3" :xl="3" :cols="12">
                            <v-text-field
                                label="BAIRRO"
                                v-model="item.nm_bairro"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="3" :lg="3" :xl="3" :cols="12">
                            <v-text-field
                            label="COMPLEMENTO"
                            v-model="item.ds_complemento"
                            outlined
                            :style="{ 'background-color': corDeFundo }"
                            hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="2" :lg="2" :xl="2" :cols="12">
                            <v-autocomplete
                                hide-details="true"
                                label="UF"
                                class="ma-0"
                                v-model="item.cd_uf"
                                outlined
                                :items="ufs"
                                :style="{ 'background-color': corDeFundo }"
                                @change="get_cidades(item.cd_uf)"
                            ></v-autocomplete>
                        </v-col>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-autocomplete
                                hide-details="true"
                                label="CIDADE"
                                class="ma-0"
                                :loading="loading_cidade"
                                v-model="item.cd_cidade"
                                item-text="nm_cidade"
                                item-value="cd_cidade"
                                outlined
                                :items="cidades"
                                :style="{ 'background-color': corDeFundo }"
                            ></v-autocomplete>
                        </v-col>
                        <v-col :md="2" :lg="2" :xl="2" :cols="12">
                            <v-text-field
                                hide-details="true"
                                label="CEP"
                                class="ma-0"
                                v-model="item.nr_cep"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="12"><v-divider class="ma-1"></v-divider></v-col>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-text-field
                                label="EMAIL"
                                v-model="item.email"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-text-field
                                label="DDD"
                                v-model="item.nr_ddd_fone"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-text-field
                                label="TELEFONE"
                                v-model="item.nr_fone"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-text-field
                                label="DDD CEL"
                                v-model="item.nr_ddd_celular"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-text-field
                                label="DDD CEL"
                                v-model="item.nr_celular"
                                outlined
                                :style="{ 'background-color': corDeFundo }"
                                hide-details="true"
                            ></v-text-field>
                        </v-col>
                        <v-col :md="4" :lg="4" :xl="4" :cols="12">
                            <v-autocomplete
                                hide-details="true"
                                label="NATURALIDADE"
                                class="ma-0"
                                :loading="loading_cidade"
                                v-model="item.cd_naturalidade"
                                item-text="nm_cidade"
                                item-value="cd_cidade"
                                outlined
                                :items="cidades"
                                :style="{ 'background-color': corDeFundo }"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="mb-2">
                        <v-col :cols="12"><v-btn :loading="loading_btn" outlined solid @click="update_paciente(item)">gravar<v-icon>mdi-content-save</v-icon></v-btn></v-col>
                        <v-col :cols="12">
                            <v-divider class="ma-1"></v-divider>
                        </v-col> 
                    </v-row>
                </td>
            </template>
        </v-data-table>
    </v-container>
</template>
<script>
    
import AppEtlService from '@/service/AppEtlService'
const api = new AppEtlService();
const siglas_estados = [
    "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA",
    "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN",
    "RS", "RO", "RR", "SC", "SP", "SE", "TO"
]
const sexo = [
    {tp_sexo:'I',value:'IDEFINIDO'},
    {tp_sexo:'M',value:'MASCULINO'},
    {tp_sexo:'F',value:'FEMININO'},
    {tp_sexo:'G',value:'IGNORADO'}
]
const tp_estado_civil = [
    {tp_estado_civil:'V',value:'VIUVO'},
    {tp_estado_civil:'U',value:'UNIAO ESTAVEL'},
    {tp_estado_civil:'I',value:'DIVORCIADO'},
    {tp_estado_civil:'D',value:'DESQUITADO'},
    {tp_estado_civil:'C',value:'CASADO'},
    {tp_estado_civil:'S',value:'SOLTEIRO'},
    {tp_estado_civil:'G',value:'IGNORADO'}
]
const headers = [
    {text:'Prontuário', value:'cd_paciente'},
    {text:'Nome', value:'nm_paciente'},
    {text:'CPF', value:'nr_cpf'},
    {text:'Nascimento', value:'nascimento'}
]
export default {
    components: { 
        Paciente:()=>import('../components/Paciente.vue'),
        AgendamentosDoPaciente:()=>import('../components/AgendamentosDoPaciente.vue'),
    },
    name:"Kpis",
    data:()=>({
        search:'',
        expanded: [],
        res: [],
        temp: [], // temporario pra manipular paciente
        ufs: siglas_estados,
        arr:[],
        tp_sexo:sexo,
        cidades:[],
        cidadanias:[],
        profissoes:[],
        ds_om_identidade:[],
        tp_estado_civil:tp_estado_civil,
        headers:headers,
        corDeFundo:'#fff',
        loading:false,
        loading_cidadanias:false,
        loading_profissoes:false,
        loading_om_identidate:false,
        loading_cidade:false,
        loading_btn:false,
        nm_paciente:'%',
        nr_cpf:'%',
    }),
    methods:{ 
        regra(){
            return [(this.nm_paciente.length>3 || this.nr_cpf>3)||'cpf ou nome são obrigatórios']
        },
        update_paciente(item){
            this.loading_btn = true
            var dt_temp = item.dt_nascimento
            item.dt_nascimento = item.nascimento = item.dt_nascimento?.split("T")[0]?.split("-")?.reverse()?.join(".")
            const {nm_paciente, tp_sexo,tp_estado_civil,ds_endereco,dt_nascimento,nm_mae,
                cd_cidadania,cd_profissao,nm_bairro,ds_complemento,nr_endereco,cd_cidade,
                nr_cep,cd_naturalidade,nr_cpf,nr_identidade,ds_om_identidade,email,
                cd_multi_empresa,nr_ddd_celular,nr_celular, nr_ddd_fone, nr_fone, nm_usuario, 
                cd_paciente} = item;
                
            let objeto = {nm_paciente, tp_sexo,tp_estado_civil,ds_endereco,dt_nascimento,nm_mae,
                cd_cidadania,cd_profissao,nm_bairro,ds_complemento,nr_endereco,cd_cidade,
                nr_cep,cd_naturalidade,nr_cpf,nr_identidade,ds_om_identidade,email,
                cd_multi_empresa,nr_ddd_celular,nr_celular, nr_ddd_fone, nr_fone, nm_usuario, 
                cd_paciente}
                this.res = Object.values(objeto)
                this.temp = objeto
                api
                .put_api(
                "etl/query-put/?cod_query=put_paciente_app",
                JSON.stringify(this.res)
                )
                .then((response) => {
                    if (response.status >= 200 && response.status <= 299) {
                        this.get_outros_contatos(this.temp)
                        alert("Dado alterado com sucesso.");
                    } 
                    else alert("Houve algum erro ao alterar os dados.");
                })
                .catch((err) => alert(err))
                .finally(() => {
                    this.loading = false
                    item.dt_nascimento = dt_temp
                });
        },
        get_pacientes(nome, cpf){
            if(!this.$refs.ref.validate()) return true
            this.get_('get_pac_nm_cpf', [nome, cpf])
        },
        post_outros_contatos(item){
            let obj = Object.values(item)
            api.create_etl('etl/query-post/?cod_query=post_outros_contatos', 
            Object.values(obj))
            .then((response) => {
                response.data
                // console.log(response.data)
            }).finally(()=>{
                this.loading_btn = false
            })
        
        },
        get_outros_contatos(item){
            const {cd_paciente, email} = item
            let obj = {cd_paciente, email}
            api.get_query('get_outros_contatos', [obj.cd_paciente])
            .then(res=>{
                let r = res.data.filter(e => e.contato === obj.email)
                if(r.length==0){
                    this.post_outros_contatos(obj)
                }
            }).finally(()=>{
                this.loading_btn = false
            })
        },
        get_(query,params){
            this.loading = true
            api.get_query(query,params)
            .then( response => { 
                this.arr = response.data
                this.arr.forEach(i=>{
                    this.get_cidades(i.cd_uf)
                    this.get_cidadanias()
                    this.get_profissoes()
                    this.get_ds_om_identidade()
                })
            })
            .catch(error => { console.log(error) })
            .finally( () => { this.loading = false })
        },
        get_cidades(params){
            this.loading_cidade = true
            api.get_query('get_cidades_uf', [params])
            .then( response => { 
                this.cidades = response.data
            })
            .catch(error => { console.log(error) })
            .finally( () => { this.loading_cidade = false })
        },
        get_ds_om_identidade(){
            this.loading_om_identidate = true
            api.get_query('get_orgao_emissor', [])
            .then( response => { 
                this.ds_om_identidade = response.data
            })
            .catch(error => { console.log(error) })
            .finally( () => { this.loading_om_identidate = false })
        },
        get_cidadanias(){
            this.loading_cidadanias = true
            api.get_query('get_cidadanias',[])
            .then( response => { 
                this.cidadanias = response.data
            })
            .catch(error => { console.log(error) })
            .finally( () => { this.loading_cidadanias = false })
        },
        get_profissoes(){
            this.loading_profissao = true
            api.get_query('get_profissao',[])
            .then( response => { 
                this.profissoes = response.data
            })
            .catch(error => { console.log(error) })
            .finally( () => { this.loading_profissoes = false })
        },
        atualiza_paciente(new_patient){
            console.log('new_patient > ',new_patient)
            this.get_pacientes('%', new_patient.nr_cpf)
        }
    }, 
    computed:{
    },
    watch:{
        nm_paciente(val){
            this.nm_paciente = val.toUpperCase()
        }
    },
    mounted(){     
    }
}
</script>